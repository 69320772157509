module.exports = [{
      plugin: require('/Users/shilman/projects/card/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/shilman/projects/card/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Nunito Sans:400,700,900"]}},
    },{
      plugin: require('/Users/shilman/projects/card/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
